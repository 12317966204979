<template>
  <FormCard
    :title="category.name ? category.name : 'Nova Categoria'"
    :status="category.status"
    @save="$emit('save')"
    @cancel="$router.push({ name: 'categories' })"
  >
    <v-form
      class="px-5 mt-5"
      ref="form"
    >
      <v-row dense>
        <v-col
          cols="12"
          md="12"
          v-if="!category.id"
        >
          <v-radio-group
            dense
            row
            mandatory
            v-model="category.type"
          >
            <v-radio
              label="Categoria Principal"
              :value="1"
            />
            <v-radio
              label="Segmento"
              :value="2"
            />
            <v-radio
              label="Datas especiais"
              :value="3"
            />
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          v-else
        >
          <h6 class="text-h6 pa-4 grey--text">
            Tipo: {{ category.type | getType }}
          </h6>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="category.name"
            label="Nome"
            placeholder="Digite um nome"
            dense
            rounded
            outlined
          />
        </v-col>
      </v-row>

      <v-row v-if="category.id && category.type == 1">
        <v-col cols="12">
          <h6 class="text-h6 px-4 grey--text">Subcategorias</h6>
        </v-col>
        <v-col cols="12">
          <v-card
            flat
            max-height="500"
            class="overflow-auto"
          >
            <v-alert
              :value="successAdding"
              dense
              text
              :type="successColor"
              transition="slide-x-transition"
              class="font-weight-bold"
            >
              {{ successText }}
            </v-alert>
            <v-data-table
              :headers="headers"
              :items="subcategories"
              hide-default-footer
            >
              <template v-slot:top>
                <div class="d-flex align-center pt-3">
                  <v-text-field
                    v-model="subcategory"
                    label="Nova subcategoria"
                    hide-details
                    dense
                    outlined
                    rounded
                  />
                  <v-spacer />
                  <v-btn
                    @click="addSubcategory"
                    color="primary"
                    outlined
                    rounded
                    :disabled="!subcategory"
                  >
                    Adicionar
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <v-text-field
                  :value="item.name"
                  @input="(e) => (newValue = e)"
                  v-if="isEditingSubcategory === item.id"
                  hide-details
                  dense
                  rounded
                  outlined
                />
                <span v-else>{{ item.name }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  v-if="isEditingSubcategory === item.id"
                  @click="updateSubcategory(item)"
                  class="mr-2"
                  :disabled="!item.name || !newValue"
                  icon
                >
                  <v-icon
                    small
                    color="success"
                  >
                    fas fa-check-circle
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  @click="editSubcategory(item)"
                  class="mr-2"
                  icon
                >
                  <v-icon
                    small
                    color="primary"
                  >
                    fas fa-pen
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="isEditingSubcategory === item.id"
                  @click="isEditingSubcategory = null"
                  class="mr-2"
                  icon
                >
                  <v-icon
                    color="error"
                    small
                  >
                    fas fa-times
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  @click="deleteSubcategory(item)"
                  class="mr-2"
                  icon
                >
                  <v-icon
                    color="error"
                    small
                  >
                    fas fa-trash
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <div
                  class="pa-2"
                  v-if="isLoadingSubcategories"
                >
                  <v-progress-linear
                    indeterminate
                    color="primary"
                  />
                </div>
                <h6
                  v-else
                  class="text-center text-h6"
                >
                  Nenhuma subcategoria cadastrada
                </h6>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </FormCard>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormCard: () => import('@/components/FormCard'),
  },
  data: () => ({
    subcategory: null,
    isLoadingSubcategories: true,
    isEditingSubcategory: null,
    successAdding: false,
    successText: '',
    successColor: '',
    newValue: null,
    oldValue: null,
    headers: [
      { text: 'Nome', align: 'start', sortable: true, value: 'name' },
      { text: '', value: 'actions', align: 'end', sortable: false },
    ],
    subcategories: [],
    profile: null,
  }),
  filters: {
    getType(value) {
      switch (value) {
        case 1:
          return 'Categoria Principal'
        case 2:
          return 'Segmento'
        case 3:
          return 'Data Especial'
      }
    },
  },
  methods: {
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    addSubcategory() {
      this.$api
        .post('set_subcategories', {
          category_id: this.category.id,
          name: this.subcategory,
        })
        .then((res) => {
          this.successColor = 'success'
          this.successText = 'Subcategoria cadastrada com sucesso'
          this.successAdding = true
          this.subcategories.unshift(res.data.data)
          this.subcategory = ''

          setTimeout(() => {
            this.successAdding = false
          }, 1000)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    editSubcategory(item) {
      this.oldValue = item.name
      this.isEditingSubcategory = item.id
    },
    getSubcategories() {
      this.$api
        .get(`get_subcategories/${this.category.id}`)
        .then((res) => {
          this.subcategories = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoadingSubcategories = false
        })
    },
    deleteSubcategory(item) {
      this.$api
        .delete(`delete_subcategory/${item.id}`)
        .then(() => {
          let idx = this.subcategories.indexOf(item)
          this.subcategories.splice(idx, 1)
          this.successColor = 'success'
          this.successText = 'Subcategoria removida com sucesso'
          this.successAdding = true

          setTimeout(() => {
            this.successAdding = false
          }, 1000)
          this.isEditingSubcategory = null
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateSubcategory(item) {
      this.$api
        .patch(`update_subcategory`, { id: item.id, name: this.newValue })
        .then(() => {
          this.successColor = 'success'
          this.successText = 'Subcategoria atualizada com sucesso'
          this.successAdding = true

          item.name = this.newValue

          setTimeout(() => {
            this.successAdding = false
          }, 1000)
          this.isEditingSubcategory = null
          this.newValue = null
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.category.id) {
      this.getSubcategories()
    }
  },
}
</script>
